<template>
<div class="ul-panel panel-sm faq-item" ref="item" :id="props.id">
    <a href="javascript:void(0)" @click="toggleShow" class="d-flex flex-row justify-content-between toggle-link" title="Раскрыть пункт FAQ">
        <p class="pe-3">{{ title }}</p>
        <img src="@/img/svg/arrow.svg" alt="Раскрыть" />
    </a>

    <div class="faq-item-body">
        <slot></slot>
    </div>
  </div>
</template>
<script setup>
import $ from 'jquery';
import { defineProps, onMounted, ref } from 'vue';
const item = ref(null)
const props = defineProps(['title', 'id', 'expanded'])

const toggleShow = function() {
    $('.faq-item.expanded').map(function() {
        if ($(this).attr('id') == props.id) {
            return;
        }
        $(this).find('.faq-item-body').slideUp();
        $(this).removeClass('expanded');
    })

    $('#' + props.id).toggleClass('expanded');
    $('#' + props.id).find('.faq-item-body').slideToggle();
}

onMounted(() => {
    if (props.expanded == true) {
        toggleShow();
    }
})
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";

#app .ul-panel.faq-item {
    background: $comment;
    box-shadow: none;

    .toggle-link {
        color: $base;

        img {
            transition-duration: 0.3s;
            transition-property: transform;
        }
    }
    
    .faq-item-body {
        display: none;
        padding: 0;
        margin-top: 15px;

        p, ul li {
            font-size: 15px;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    &.expanded {
        .toggle-link img {
            transform: rotate(180deg);
        }
    }
}
</style>