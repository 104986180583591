<template>
    <div id="screen1">
        <div id="screen1-wrap">
            <div id="particles"></div>
            <div class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4"></div>
                            <div class="col" id="screen1-content">
                                <div class="contacts d-flex justify-content-end">
                                    <div class="d-flex">
                                        <a :href="'mailto:' + email" title="Написать нам на почту" class="d-flex me-4 align-items-center" target="_blank">
                                            <img src="@/img/svg/mail.svg" width="24" height="24" alt="E-mail" />
                                            <span class="d-none d-lg-inline ps-2">{{ email }}</span>
                                        </a>
                                        <a :href="'tel:' + phone" title="Позвонить нам" target="_blank">
                                            <img src="@/img/svg/phone.svg" width="24" height="24" alt="Phone" />
                                            <span class="d-none d-lg-inline ps-2">{{ phone }}</span>
                                        </a>
                                    </div>
                                </div>

                                <div class="d-flex align-items-start hero-box">
                                    <div class="hero d-flex flex-column align-content-center justify-content-center">
                                        <div class="d-flex justify-content-center justify-content-md-start align-items-end mb-4">
                                            <a href="/" title="ulsoft.pro" id="logo-img">
                                                <img src="@/img/svg/ulsoft.svg" height="140" alt="ULSoft" />
                                            </a>
                                            <h1>UL Soft</h1>
                                        </div>
                                        
                                        <h2 class="text-center text-md-start">От идеи до реализации — полный цикл разработки цифровых решений.</h2>
                                        <div class="mt-4 text-center text-md-start">
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#submit-form-modal">
                                                Оставить заявку
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import * as vars from '@/constants/constants.js'
    import {onMounted, onUnmounted } from 'vue'
    import '@/assets/js/particles.js'
    import debounce from 'debounce'

    const email = vars.EMAIL
    const phone = vars.PHONE
    const particles = require('@/assets/js/particles.json')

    const initializeParticles = () => {
        const number = Math.round(window.innerWidth * window.innerHeight / 32000)
        particles.particles.number.value = number
        window.particlesJS('particles', particles);
    }

    onMounted(() => {
        initializeParticles()
        window.addEventListener("resize", debounce(initializeParticles, 300));
    })

    onUnmounted(() => {
        window.removeEventListener("resize", debounce(initializeParticles, 300));
    })
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";

#screen1 {
    background-image: url('@/img/bg/bg4.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: $primary;
    min-height: 100lvh;
    
}

#screen1-wrap {
    background: rgba(31, 40, 51, 0.85);
    $pdLeft: 50px;
    $cornerHeight: 95px;
    min-height: 100lvh;

    #screen1-content {

        .hero-box {
            height: 100%;
            padding-top: 140px;

            #logo-img {
                margin-right: 30px;
            }

            img {
                padding-bottom: 14px;
            }

            h1 {
                padding: 0;
                margin: 0;
                line-height: 140px;
                
            }

            h2 {
                color: $counter;
            }
        }
    }

    #particles {
        position: absolute;
        height: 100vh;
        width: 100%;
    }

    .content {
        background-image: url('@/img/bg/robot3.png');
        background-repeat: no-repeat;
        background-size: 30%;
        background-position: 5% 40%;
        padding-top: 25px;
        position: absolute;
        height: 100vh;
        width: 100%;
    }

    #robot {
        width: 100%;
        opacity: 0.9;
    }
}

@media (max-width: 1199.98px) { 
    #screen1-wrap {
        .content {
            background: none;
        }

        #screen1-content {
            h2 {
                font-size: 40px;
            }
        }
    }

    @media (max-width: 768px) { 
        #screen1-wrap {
            #screen1-content {

            }
        }
    }

    @media (max-width: 575.98px) { 
        #logo-img img {
            height: 80px;
        }

        #screen1-wrap {
            #screen1-content {
                .hero-box {
                    h1 {
                        line-height: 80px;
                    }
                }

                h2 {
                    font-size: 30px;
                }
            }
        }
    }
 }

</style>