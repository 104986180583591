<template>
    <div id="screen4">
        <div class="wrap">
            <div class="container">
                <div class="ul-panel d-flex flex-xl-row flex-column mb-4">
                    <h3 class="mb-4 mb-xl-0 me-xl-5">Выберите нас для реализации своего проекта и получите скидку</h3>
                    <div class="d-flex align-items-lg-center align-items-start flex-column flex-lg-row">
                        <a :href="whatsappUrl" title="Написать нам в WhatsApp" target="_blank">
                            <button class="me-lg-4 mb-3 mb-lg-0">
                            <img src="@/img/svg/whatsapp.svg" alt="WhatsApp" width="15" />
                                Написать в WhatsApp
                            </button>
                        </a>
                        <a :href="telegramUrl" title="Написать нам в Telegram" target="_blank">
                            <button>
                                <img src="@/img/svg/telegram.svg" alt="Telegram" width="15" />
                                Написать в Telegram
                            </button>
                        </a>
                    </div>
                </div>

                <div class="ul-panel tusk-panel d-flex flex-xl-row flex-column">
                    <div class="row">
                        <div class="col-12 col-lg-6 mb-xl-4">
                            <div class="d-flex align-items-center company-name">
                                <img src="@/img/icons/logo.png" height="63" />
                                <h3 class="pt-2 mt-1 ps-3">UL Soft</h3>
                            </div>
                            <h3>Успешный продукт, которым вы можете начать пользоваться прямо сейчас</h3>
                            <div class="d-flex flex-column flex-md-row pt-2">
                                <a :href="tuskAppStoreUrl" title="Скачать Tusk в App Store" class="me-4 mb-3" target="_blank">
                                    <img src="/img/icons/appstore.png" height="45" alt="Tusk в App Store" />
                                </a>
                                <a :href="tuskGooglePlayUrl" title="Скачать Tusk в Google Play" target="_blank" class="mb-3">
                                    <img src="/img/icons/googleplay.png" height="45" alt="Tusk в Google Play" />
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 d-flex align-content-end align-items-end justify-content-center">
                            <img src="@/img/tusk_app.png" width="400" class="mt-5" alt="Приложение Tusk" />
                        </div>
                    </div>
                </div>

                <div class="row d-none d-lg-flex align-items-center mt-5">
                    <div class="col">
                        <h3>Часто задаваемые вопросы</h3>
                    </div>
                    <div class="col">
                        <h3>Не нашли ответа на свой вопрос? Напишите нам</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <h2 class="d-lg-none mt-5">Часто задаваемые вопросы</h2>
                        <div id="faq" class="ul-panel">
                            <FaqItem title="Что такое разработка и как проходит процесс разработки?" id="faq1">
                                <p>
                                    Разработка сайтов и мобильных приложений включает в себя создание, проектирование и поддержку веб-сайтов и приложений для мобильных устройств. Мы предлагаем услуги по разработке, дизайну, тестированию и запуску проектов, а также их дальнейшему обслуживанию. Процесс разработки включает несколько этапов:
                                </p>
                                <ul>
                                    <li>Анализ требований: Мы собираем информацию о ваших потребностях и целях.</li>
                                    <li>Проектирование: Создаем прототипы и макеты, чтобы визуализировать проект.</li>
                                    <li>Разработка: Переходим к программированию и созданию функционала.</li>
                                    <li>Тестирование: Проверяем продукт на наличие ошибок и соответствие требованиям.</li>
                                    <li>Запуск: Выпускаем проект в эксплуатацию и обеспечиваем его работу.</li>
                                    <li>Поддержка: Предоставляем услуги по технической поддержке и обновлениям.</li>
                                </ul>
                            </FaqItem>
                            <FaqItem title="Как долго длится разработка сайта или приложения?" id="faq2">
                                <p>
                                    Сроки разработки зависят от сложности проекта. Обычно создание простого сайта занимает от 6 до 9 недель, 
                                    а мобильные приложения могут потребовать от 8 до 16 недель. Мы всегда предоставляем предварительную оценку сроков и прописываем согласованные сроки в договоре.
                                </p>
                            </FaqItem>
                            <FaqItem title="Какие вы предлагаете условия работы и оплаты? Есть ли возможность оплаты в рассрочку?" id="faq3">
                                <p>
                                    Сотрудничество полностью строится на официальной договороной основе. Договор заключается с юридическим лицом, каждый оплаченный счет закрывается актом. 
                                    Также, мы предлагаем гибкие условия оплаты, включая возможность рассрочки. Обсудите это с нашей командой, и мы найдем подходящее решение.
                                </p>
                            </FaqItem>
                            <FaqItem title="Сколько стоит разработка сайта или приложения?" id="faq4">
                                <p>
                                    Стоимость разработки зависит от множества факторов, включая функциональность, дизайн и платформу. Мы предлагаем индивидуальные расценки в зависимости от ваших потребностей. Примерные цены указаны в нашем прайсе, для получения подробной сметы, пожалуйста, свяжитесь с нами.
                                </p>
                            </FaqItem>
                            <FaqItem title="Предоставляете ли вы услуги по поддержке и обновлению?" id="faq5">
                                <p>
                                    Да, мы предлагаем услуги по технической поддержке проектов, реализованных нашей компанией, и регулярным обновлениям для ваших сайтов и приложений, чтобы гарантировать их безопасность и актуальность.
                                </p>
                            </FaqItem>
                            <FaqItem title="Какие технологии вы используете для разработки сайтов и мобильных приложений?" id="faq6">
                                <p>
                                    Для разработки сайтов мы используем такие системы управления контентом как 1СБитрикс, Wordpress и др. Так же, если для вас время решает - 
                                    мы готовы работать с готовыми решениями, такими как aspro или krayt. Мы разрабатываем приложения для различных платформ, включая iOS и Android. 
                                    Мы также создаем кроссплатформенные решения с использованием технологий, таких как React Native и Flutter.
                                </p>
                            </FaqItem>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <h2 class="d-lg-none mt-5">Не нашли ответа на свой вопрос? Напишите нам</h2>
                        <SubmitForm />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import * as vars from '@/constants/constants.js';
import FaqItem from './faq_item.vue';
import SubmitForm from '../submit_form/submit_form.vue';
const whatsappUrl = vars.WHATSAPP_URL;
const telegramUrl = vars.TELEGRAM_URL;
const tuskAppStoreUrl = vars.TUSK_APP_STORE_URL;
const tuskGooglePlayUrl = vars.TUSK_GOOGLE_PLAY_URL;

</script>
<style lang="scss">
@import "@/assets/scss/main.scss";

#screen4 {
    padding-top: 80px;
    padding-bottom: 100px;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(218,218,218,1) 86%, rgba(197,198,199,1) 100%);
    

    > .container > .ul-panel 
    {
        margin-left: 0;
        margin-right: 0;
        padding: 40px 60px;
    }

    .tusk-panel {
        padding-bottom: 0 !important;
    }

    button {
        min-width: 270px;
        img {
            margin-right: 5px;
        }
    }

    h2 {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
    }

    .company-name {
        padding-bottom: 30px;

        h3 {
            text-align: left;
            background: linear-gradient(180deg, #64F6EB 24.18%, #46A29F 104.4%);
            font-size: 26px;
            font-weight: 700;
            line-height: 31.2px;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    // // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
        > .container > .ul-panel 
        {
            padding: 40px 30px;
        }

        > .container > .tusk-panel {
            padding-bottom: 0;

            h2 {
                font-size: 30px;
                font-weight: 700;
                line-height: 39px;
            }
        }
    }

    #faq {
        padding-top: 30px;
        background: rgb(48,74,104);
        background: linear-gradient(180deg, rgba(48,74,104,1) 0%, rgba(43,73,96,1) 68%, rgba(26,46,61,1) 100%);
        margin-right: 15px;

        .ul-panel {
            margin-bottom: 20px;
        
            .card-body {
                margin-top: 10px;
                padding: 15px 20px 15px 20px;

                p {
                    font-size: 15px;
                }

                p:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        a {
            p {
                margin-bottom: 0;
                color: $base;
            }
        }
   }

    // >= lg
    @media (min-width: 992px) {
        #screen5-wrap > .container > .row > div {
            &:first-of-type {
                padding-right: 50px;
            }

            &:last-of-type {
                padding-left: 50px;
            }
        }
    }

    @media  (max-width: 992px) {
        .ul-panel {
            img {
                max-width: 100%;
            }
        }
    }
}
</style>
