<template>
  <Screen1Item />
  <Screen2Item />
  <Screen3Item />
  <Screen4Item />
  <FooterItem /> 
  <SubmitFormModal />
  <TncItem />
</template>

<script>
import Screen1Item from './components/screen1/screen1_item.vue';
import Screen2Item from './components/screen2/screen2_item.vue';
import Screen3Item from './components/screen3/screen3_item.vue';
import Screen4Item from './components/screen4/screen4_item.vue';
import FooterItem from './components/footer/footer_item.vue';
import SubmitFormModal from './components/submit_form/submit_form_modal.vue';
import TncItem from './components/tnc/tnc_item.vue';

export default {
  name: 'App',
  components: {
    Screen1Item,
    Screen2Item,
    Screen3Item,
    Screen4Item,
    FooterItem,
    SubmitFormModal,
    TncItem
  }
}
</script>

<style>
@import url('bootstrap/dist/css/bootstrap.min.css');
@import 'pretty-checkbox/dist/pretty-checkbox.min.css';
@import url('@/assets/scss/main.scss');
</style>
