<template>
  <div>
    <div class="modal fade" aria-hidden="true" id="submit-form-modal" aria-labelledby="submit-modal-title" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="submit-modal-title">Заполните форму</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"><img src="@/img/svg/close.svg" /></button>
          </div>
          <div class="modal-body">
            <SubmitForm />
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" aria-hidden="true" id="submit-success-modal" aria-labelledby="submit-modal-title" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="submit-modal-title">Форма отправлена</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"><img src="@/img/svg/close.svg" /></button>
          </div>
          <div class="modal-body">
            <p>Спасибо, мы свяжемся с Вами в ближайшее время.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" aria-hidden="true" id="submit-fail-modal" aria-labelledby="submit-modal-title" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="submit-modal-title">Ошибка отправки</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"><img src="@/img/svg/close.svg" /></button>
          </div>
          <div class="modal-body">
            <p>Не удалось отправить данные. Пожалуйста, повторите попытку позже либо свяжитесь с нами через контакты, указанные в шапке сайта.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from '@/stores/modal'
import { storeToRefs } from 'pinia'
import { Modal } from 'bootstrap'
import { watch } from 'vue';
import SubmitForm from './submit_form.vue';

const store = useModalStore()
const { shown } = storeToRefs(store)
watch(shown, () => {
  console.log('isLoggedIn ref changed, do something! ' + shown.value)
  if (shown.value == true) {
    new Modal('#submit-form-modal', {})
  }
})
</script>
<style lang="scss">
@use "@/assets/scss/main.scss";
@use "sass:color";

#headlessui-portal-root .relative {
  background: transparent !important;
}

#headlessui-portal-root, #app {
  .ul-modal-content {
    background: color.adjust(main.$primary, $lightness: -11%);
    padding: 30px 25px;
    border-radius: 15px;
  }

  .btn-close {
    padding: 10px;
  }


  .close {
    margin-top: 6px; 


    &:hover img {
      filter: invert(91%) sepia(72%) saturate(3351%) hue-rotate(209deg) brightness(581%) contrast(45%);
    }
  }

  h3 {
    margin-bottom: 20px;
    color: main.$base;
  }
}
  
</style>