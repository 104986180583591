<template>
    <div id="footer">
        <div class="foonter-content">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-3 d-flex align-items-center order-1">
                        <img src="@/img/svg/ulsoft.svg" height="63" alt="ULSoft" />
                        <h3 class="ms-3">UL Soft</h3>
                    </div>
                    <FooterContactUs class="d-xl-none mt-3 mt-md-0 order-2" />
                    <div class="col-md-8 col-xl-3 d-flex flex-column justify-content-center align-items-start my-4 order-last order-md-3">
                        <a href="javascript:void(0)" @click="modalStore.tncShown = true" title="Открыть политику конфиденциальности">
                            <p>Политика конфиденциальности</p>
                        </a>
                        <p class="mb-0">© 2024. Вся информация защищена</p>
                    </div>
                    <div class="col-12 col-md-4 col-xl-2 d-flex flex-column justify-content-center align-items-xl-center align-items-md-end align-items-start mt-4 mt-md-0 order-4">
                        <a class="d-flex align-items-center mb-3" :href="'mailto:' + email" title="Написать нам на E-mail" target="_blank">
                            <img src="@/img/svg/mail.svg" height="24" class="me-2" alt="E-mail" />
                            <span class="">{{ email }}</span>
                        </a>
                        <a class="d-flex" :href="'tel:' + phone" title="Позвонить нам" target="_blank">
                            <img src="@/img/svg/phone.svg" height="24" class="me-2" alt="Phone" />
                            <span>{{ phone }}</span>
                        </a>
                    </div>
                    <FooterContactUs class="col-xl-4 d-none d-xl-flex order-5" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import * as vars from '@/constants/constants.js';
const email = vars.EMAIL;
const phone = vars.PHONE;
import { useModalStore } from '@/stores/modal'
import FooterContactUs from './contact_us.vue';

const modalStore = useModalStore()
</script>
<style lang="scss">
@use "@/assets/scss/main.scss";

#footer {
    background-image: url('@/img/bg/bg5.jpg');
    background-position: 0% 100%;
    background-size: 100% auto;
    

    .foonter-content {
        padding-top: 40px;
        padding-bottom: 40px;
        background: rgb(62,71,80);
        background: linear-gradient(180deg, rgba(62,71,80,0.9) 0%, rgba(31,40,50,0.9) 86%, rgba(27,35,46,0.9) 100%);

        h3 {
            line-height: 40px;
            vertical-align: middle;
            margin: 0;
            padding-top: 10px;
            color: white;
            background: linear-gradient(180deg, main.$accent 24.18%, main.$accent2 104.4%);
            text-shadow: 2px 2px 10px rgba(main.$accent2, 0.3);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        p, a, a span {
            font-size: 16px;
            color: main.$comment;
        }
    }

    button.contact-us {
        min-width: 210px;
    }
}

 /// < md
 @media (max-width: 767.98px) {
    #footer {
        padding-top: 20px;
    }
}
</style>